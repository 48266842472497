import React , {useState} from "react"
import styles from "./shopware-comparison.module.css"

const ShopwareComparison = (props) => {

const [count,setCount]= useState(false);

const clickHnadler = () => {
    setCount(!count)
}

  return (
    <section className={styles.mainContainer}>
      <div className={styles.comparisonBody}>
        {props.headline &&
          <div className={styles.h2}
          dangerouslySetInnerHTML={{
            __html: props.headline,
          }}
          ></div>
        }
        <div className={`${styles.table}`} style={ count ? { maxHeight:'100%', position: 'static'} : {}}
          dangerouslySetInnerHTML={{
            __html: props.table,
          }}
        >
        </div>
        <button className={`${styles.mehrAnzeigen} ${count ? styles.wenigerAnzeigen : ''}`} onClick={clickHnadler}>{count ? "weniger anzeigen" : "mehr anzeigen"}</button>
      </div>
    </section>
  )
}

export default ShopwareComparison
