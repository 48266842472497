import React from "react"
import styles from "./Shopware-Extension.module.css"

const star = rate => {
  let content = [];
  for (let i = 0; i < rate; i++) {
    content.push(<div className={styles.star} key={i}></div>);
  }
  return content;
};

const shopwareExtention = (props) => {
  return (
    <section className={styles.mainContainer}>
      <div className={styles.shopwareContainer}>
        <div className={styles.businessIcon} style={props.img != null ? {backgroundImage: `url(${props.img})`} : {}}></div>
        <h2 className={`${styles.h2} ${styles.hideOnDesktop}`}>Shopware-Extension Entwicklung</h2>
        <div className={`${styles.shopwareExtention} ${styles.hideOnMobile}`}>
          {props.headline &&
            <h2 className={styles.h2}>{props.headline}</h2>
          }
          <div className={styles.shopwareExtParaContainer}>
            {props.textAreaLeft &&
              <p>
                {props.textAreaLeft}
              </p>
            }
            {props.textAreaRight &&
              <p>
                {props.textAreaRight}
              </p>
            }
          </div>
        </div>
      </div>
      <div className={styles.extentionRate}>
        <div className={styles.numbersContainer}>
          <div className={`${styles.numberContainer} ${styles.firstNumber}`}>
            {props.zahl1 &&
              <p className={styles.number}>{props.zahl1}</p>
            }
            {props.was1 &&
              <p className={styles.p}>{props.was1}</p>
            }
          </div>
          <div className={styles.numberContainer}>
            {props.zahl2 &&
              <p className={styles.number}>{props.zahl2}</p>
            }
            {props.was2 &&
              <p className={styles.p}>{props.was2}</p>
            }
          </div>
        </div>
        <div className={styles.rectangleAndButton}>
          <div className={styles.rectangle}>
            <div className={styles.starsContainer}>
              <div className={styles.starAndText}>
              {props.rating1 &&
                <div className={styles.strasFlexContainer}>
                 { star(props.rating1)}
                </div>
              }
                {props.rating1Was &&
                  <p className={styles.marginOnMobile}>{props.rating1Was}</p>
                }
              </div>
              <div className={styles.starAndText}>
              {props.rating2 &&
                <div className={styles.strasFlexContainer}>
                  { star(props.rating2)}
                </div>
              }
                {props.rating2Was &&
                  <p>{props.rating2Was}</p>
                }
              </div>
              <div className={styles.starAndText}>
              {props.rating3 &&
                <div className={styles.strasFlexContainer}>
                  { star(props.rating3)}
                </div>
              }
                {props.rating3Was &&
                  <p>{props.rating3Was}</p>
                }
              </div>
              <div className={styles.starAndText}>
                {props.rating4 &&
                  <div className={styles.strasFlexContainer}>
                  { star(props.rating4)}
                  </div>
                }
                {props.rating4Was &&
                  <p>{props.rating4Was}</p>
                }
              </div>
            </div>
          </div>
          {props.buttonName &&
            <a href={props.buttonUrl} target="_blank" rel="noreferrer">
              <button className={styles.button}>{props.buttonName}</button>
            </a>
          }
        </div>
      </div>
    </section>
  )
}

export default shopwareExtention
