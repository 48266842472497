import React, { useState, useEffect } from "react"
import window from "global"
import { Swipeable } from "react-swipeable"
import styles from "./component.module.css"
import BgImg from "../../../images/dw-pattern-outline.png"

const OurProjects = props => {
  const [count, setCount] = useState(0)

  const max = props.items.length
  const [displaySlider, setDisplaySlider] = useState(true)
  useEffect(() => {
    if (props.items.length < 2) {
      setDisplaySlider(false)
    }
  }, [props.items.length])

  const [displayButton, setDisplayButton] = useState(true)
  function nextClickHandler() {
    if (window.innerWidth >= 2700) {
      count < max - 3 ? setCount(count + 1) : setDisplayButton(false)
    }
    if (window.innerWidth < 2700 && window.innerWidth > 2150) {
      count < max - 2 ? setCount(count + 1) : setDisplayButton(false)
    }
    if (window.innerWidth < 2149 && window.innerWidth > 1550) {
      count < max - 2 ? setCount(count + 1) : setDisplayButton(false)
    }
    if (window.innerWidth < 1550) {
      count < max - 1 ? setCount(count + 1) : setDisplayButton(false)
    }
  }

  function prevClickHandler() {
    if (count >= 1) {
      setCount(count - 1)
      setDisplayButton(true)
    }
  }

  return (
    <div className={styles.componentContainer}>
      <div className={styles.topContainer}>
        <h2 className={styles.h1}>Unsere {props.title} Projekte</h2>
        {displaySlider && (
          <div className={styles.slider}>
            <button
              aria-label="Previouse item"
              className={`${styles.leftArrow} ${
                count > 0 ? styles.activeLeftArrow : ""
              }`}
              onClick={prevClickHandler}
            ></button>
            <button
              aria-label="Next item"
              className={`${styles.rightArrow} ${
                !displayButton ? styles.opacityOn : ""
              }`}
              onClick={nextClickHandler}
            ></button>
          </div>
        )}
      </div>
      <Swipeable
        onSwipedRight={prevClickHandler}
        onSwipedLeft={nextClickHandler}
      >
        <div className={`${styles.projectsContainer}`}>
          <div className={styles.bgImg}>
            <img src={BgImg} alt="bgimg" />
          </div>
          <ol
            style={{
              transform: "translateX(" + (100 / max) * -1 * count + "%)",
            }}
          >
            {props.items.map((item, index) => {
              return (
                <li key={index} className={`${styles.projectItem}`}>
                  <a href={"/" + item.slug + "/"}>
                    {item.teaserbild.fluid && (
                      <img src={item.teaserbild.fluid.src} alt="project" />
                    )}
                    <span className={styles.projectThechnologies}>
                      {item.kategorie.map((names, index, arr) => {
                        if (arr.length - 1 === index) {
                          return names.name
                        } else {
                          return names.name + " / "
                        }
                      })}
                    </span>
                    <h3 className={styles.h2}>
                      {item.kundeKurz}
                      <span></span>
                    </h3>
                    <p>{item.teaserText}</p>
                  </a>
                </li>
              )
            })}
          </ol>
        </div>
      </Swipeable>
    </div>
  )
}

export default OurProjects
