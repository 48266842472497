import React from "react"
import TopHeaderMenu from "../../../components/header/top-header-with-menu/topHeaderWithMenu"
import styles from "./header.module.css"

const Header = props => {
  return (
    <section className={styles.sectionContainer}>
      <TopHeaderMenu page={"themenseite"} url={props.url} />
      <div className={styles.textandImageContainer}>
        <div className={styles.textContainer}>
          {props.Headline && <h1 className={styles.h1}>{props.Headline}</h1>}
          {props.HeaderText && <p>{props.HeaderText}</p>}
          {props.HeaderTextHtml && <div dangerouslySetInnerHTML={{__html: props.HeaderTextHtml}} /> }
          {props.ButtonUrl &&
            <a href={props.ButtonUrl}>
              <button className={styles.primaryButton}>
                {props.ButtonName != null
                  ? props.ButtonName
                  : "Bilde ein Team mit uns"}
              </button>
            </a>
          }
        </div>
        <div className={styles.imagesContainer}>
          {props.Img1 &&
            <div
              className={styles.mainImage}
              style={{ backgroundImage: `url(${props.Img1})` }}
            />
          }
          {(props.Img2 || props.Img3) &&
            <div className={styles.img2AndImg3FlexContainer}>
              {props.Img2 &&
                <div
                  className={styles.secondaryImage2}
                  style={{ backgroundImage: `url(${props.Img2})` }}
                />
              }
              {props.Img3 &&
                <div
                    className={styles.certificateImg1}
                    style={{backgroundImage: `url(${props.Img3})`}}
                />
              }
            </div>
          }
        </div>
      </div>
    </section>
  )
}

export default Header
