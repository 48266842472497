import React from "react"

import styles from "./shopware-description.module.css"

const shopwareDescription = (props) => {
  let color = ""
  if (props.bgColor === "whit") {
    color = "#ffffff"
  } else if (props.bgColor === "grey") {
    color = "#FAFAFA"
  } else if (props.bgColor === "dark grey") {
    color = "#F1F4F5"
  }
  return (
    <div style={color !== "" ? { backgroundColor: color} : {}}>
    <section className={styles.mainContainer}>
      {props.Headline &&
        <h2 className={styles.h1}>{props.Headline}</h2>
      }
      <div className={styles.descriptionContainer}>
        <div className={styles.itemsContainer}>
          <div className={styles.item}>
            <div className={`${styles.icon} ${styles.icon1Bg} ${props.Iconshadow ? "shadow" : ''}`} style={props.Icon1 != null ? {backgroundImage: `url(${props.Icon1})`} : {backgroundImage: null}}></div>
            {props.List1 &&
              <p>{props.List1}</p>
            }
          </div>
          {props.Icon2 &&
            <div className={`${styles.item}`}>
              <div className={`${styles.icon} ${styles.icon2Bg} ${props.Iconshadow ? "shadow" : ''}`} style={props.Icon2 != null ? {backgroundImage: `url(${props.Icon2})`} : {backgroundImage: null}}></div>
              {props.List2 &&
                <p>{props.List2}</p>
              }
            </div>
          }
          {props.Icon3 &&
            <div className={styles.item}>
              <div className={`${styles.icon} ${styles.icon3Bg} ${props.Iconshadow ? "shadow" : ''}`} style={props.Icon3 != null ? {backgroundImage: `url(${props.Icon3})`} : {backgroundImage: null}}></div>
              {props.List3 &&
                <p>{props.List3}</p>
              }
            </div>
          }
          {props.Icon4 &&
            <div className={styles.item}>
              <div className={`${styles.icon} ${styles.icon4Bg} ${props.Iconshadow ? "shadow" : ''}`} style={props.Icon4 != null ? {backgroundImage: `url(${props.Icon4})`} : {backgroundImage: null}}></div>
              {props.List4 &&
                <p>{props.List4}</p>
              }
            </div>
          }
          {props.Icon5 &&
            <div className={styles.item}>
              <div className={`${styles.icon} ${styles.icon4Bg} ${props.Iconshadow ? "shadow" : ''}`} style={props.Icon5 != null ? {backgroundImage: `url(${props.Icon5})`} : {backgroundImage: null}}></div>
              {props.List5 &&
                <p>{props.List5}</p>
              }
            </div>
          }
          {props.Icon6 !== null &&
            <div className={styles.item}>
              <div className={`${styles.icon} ${styles.icon4Bg} ${props.Iconshadow ? "shadow" : ''}`} style={props.Icon6 != null ? {backgroundImage: `url(${props.Icon6})`} : {backgroundImage: null}}></div>
              {props.List6 &&
                <p>{props.List6}</p>
              }
            </div>
          }
        </div>
          <div className={styles.descriptiionImgAndText}>
            <div className={`${styles.img} ${props.Shadow ? "shadow" : ''}`}style={props.Img != null ? {backgroundImage: `url(${props.Img})`} : {backgroundImage: null}}></div>
            {props.ImgDesc &&
              <p>{props.ImgDesc}</p>
            }
          </div>
      </div>
    </section>
    </div>
  )
}

export default shopwareDescription
