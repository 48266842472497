import React from "react"
import styles from "./qoute.module.css"

const qoute = (props) => {
  return (
    <section className={styles.mainContainer}>
      <div className={styles.qoute}>
        {props.quote &&
        <p>
          {props.quote}
        </p>
        }
        <div className={styles.icon}></div>
      </div>
    </section>
  )
}

export default qoute
