import React from "react"
import { graphql, StaticQuery } from "gatsby"
import MicroData from "./components/microdata/index"
import tel from "../../lib/tel"

import styles from "./footer.module.css"
import Logo from "../../images/icon.png"

const footer = props => {
  return (
    <StaticQuery
      query={graphql`
        query FooterDataQuery {
          allDatoCmsTemplateTexte {
            nodes {
              companyAddressCity
              companyAddressZip
              companyStreetAddress
              labelCopyright
              phoneNumber
            }
          }
          allDatoCmsNavigationFooter(sort: { fields: position, order: ASC }) {
            nodes {
              title
              id
              link {
                ... on DatoCmsLeistungsdatenbank {
                  id
                  slug
                  internal {
                    type
                  }
                }
                ... on DatoCmsNews {
                  id
                  slug
                  internal {
                    type
                  }
                }
                ... on DatoCmsSeiten {
                  id
                  internal {
                    type
                  }
                  slug
                }
                ... on DatoCmsStellenangebote {
                  id
                  slug
                  internal {
                    type
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <footer className={styles.footer}>
          <div className={styles.footerContainer}>
            <div className={styles.footerPart1}>
              <div className={styles.footerPart1Container}>
                <img src={Logo} alt="Digitalwert Icon" />
                <div className={styles.companyAdress}>
                  <p className={styles.h4}>digitalwert</p>
                  <address>
                    {data.allDatoCmsTemplateTexte.nodes[0].companyStreetAddress}
                    <br />
                    {
                      data.allDatoCmsTemplateTexte.nodes[0].companyAddressZip
                    }{" "}
                    {data.allDatoCmsTemplateTexte.nodes[0].companyAddressCity}
                  </address>
                  <a
                    href={tel("[ 0351 ] 417 54 8035")}
                    content={tel("[ 0351 ] 417 54 8035", true)}
                  >
                    {data.allDatoCmsTemplateTexte.nodes[0].phoneNumber}
                  </a>
                </div>
                <div className={styles.socials}>
                  <p className={styles.h4}>Socials</p>
                  <a
                    href="https://www.facebook.com/digitalwert/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <p>Facebook</p>
                  </a>
                  <a
                    href="https://www.xing.com/companies/digitalwert/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <p>XING</p>
                  </a>
                  <a
                    href="https://www.instagram.com/digitalwert/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <p>Instagram</p>
                  </a>
                  <a
                    href="https://de.linkedin.com/company/digitalwert/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <p>Linkedin</p>
                  </a>
                </div>
                {/*
                 <div className={styles.formDiv}>
                  <p className={styles.h4}>
                    Newsletter. Sei auf dem neusten Stand.
                  </p>
                  <form className={styles.form}>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="E-Mail Adresse eintragen"
                    />
                    <button />
                  </form>
                </div>
                 */}
              </div>
            </div>
            <div className={styles.footerPart2}>
              <div className={styles.footerPart2Container}>
                <p>{data.allDatoCmsTemplateTexte.nodes[0].labelCopyright}</p>
                <ul>
                  <li>
                    <button
                      onClick={() => {
                        if (
                          !props.cookieConsentRef ||
                          !props.cookieConsentRef.current
                        )
                          return
                        props.cookieConsentRef.current.setVisible()
                      }}
                    >
                      Cookie Einstellungen
                    </button>
                  </li>
                  {data.allDatoCmsNavigationFooter.nodes.map(
                    (footerNodes, index) => {
                      return (
                        <li key={index}>
                          <a
                            href={
                              "/" +
                              (footerNodes.link ? footerNodes.link.slug : "")
                            }
                            key={index}
                          >
                            {footerNodes.title}
                          </a>
                        </li>
                      )
                    }
                  )}
                </ul>
              </div>
            </div>
          </div>
          <MicroData
            //locale={locale}
            config={data.allDatoCmsTemplateTexte.nodes[0]}
          />
        </footer>
      )}
    />
  )
}

export default footer
