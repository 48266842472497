export default (image) => {
  if (!image) {
    return null;
  }

  if (image.fluid) {
    return image.fluid.src;
  }

  if (image.url) {
    return image.url;
  }

  return null;
}
