import React, { useState, useRef } from "react"
import { graphql } from "gatsby"
import styles from "../styles/themenseite.module.css"
import { Helmet } from "react-helmet"
import imageSrc from "./../lib/image-src"

import Header from "../components/themenseite-components/header/header"
import ShopwareDescription from "../components/themenseite-components/shopware-description/shopware-description"
import PicDivider from "../components/themenseite-components/picture-divider/picturre-divider"
import ShopwareComparison from "../components/themenseite-components/shopware-comparison/shopware-comparison"
import Qoute from "../components/themenseite-components/qoute/qoute"
import ShopwareExtention from "../components/themenseite-components/Shopware-Extension/Shopware-Extension"
import OurProjects from "../components/themenseite-components/our-projects/our-projects"
import Footer from "../components/footer/footer"
import CookieConsent from "../components/cookie-consent";

export default ({ data, location }) => {
  const cookieConsentRef = React.createRef();

  const [play, setPlay] = useState(false)
  const vidRef = useRef(null)
  function clickHnadler() {
    setPlay(true)
    vidRef.current.play()
    vidRef.current.setAttribute("controls", "controls")
  }
  function pauseHandler() {
    setPlay(false)
    vidRef.current.pause()
    vidRef.current.removeAttribute("controls")
  }
  let validNodes = []

  data.allDatoCmsReferenz.nodes.forEach(node => {
    data.datoCmsLeistung.kategorie.forEach(category => {
      if (node.kategorie.map(c => c.name).includes(category.name)) {
        validNodes.push(node)
      }
    })
  })

  return (
    <div>
      <CookieConsent locale="de" visibilityRef={cookieConsentRef} />
      <Helmet htmlAttributes={{ lang: "de" }} defer={false}>
        <title>{data.datoCmsLeistung.seo.title}</title>
        <meta
          name="description"
          content={data.datoCmsLeistung.seo.description}
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Header
        url={location.href}
        ButtonName={data.datoCmsLeistung.buttonName}
        ButtonUrl={data.datoCmsLeistung.buttonUrl}
        Headline={data.datoCmsLeistung.themenseiteTitle}
        HeaderText={data.datoCmsLeistung.teaserText}
        Img1={imageSrc(data.datoCmsLeistung.image1)}
        Img2={imageSrc(data.datoCmsLeistung.image2)}
        Img3={imageSrc(data.datoCmsLeistung.image3)}
        Img1Alt={
          data.datoCmsLeistung.image1?.alt !== null
            ? data.datoCmsLeistung.image1?.alt
            : "image"
        }
        Img2Alt={
          data.datoCmsLeistung.image2?.alt !== null
            ? data.datoCmsLeistung.image2?.alt
            : "image"
        }
        Img3Alt={
          data.datoCmsLeistung.image3?.alt !== null
            ? data.datoCmsLeistung.image3?.alt
            : "image"
        }
      />
      {data.datoCmsLeistung.content.map((contentElement, i) => {
        let color = ""
        if (contentElement.backgroundColor === "Schwarz") {
          color = "#232020"
        } else if (contentElement.backgroundColor === "Weiß") {
          color = "#ffffff"
        } else if (contentElement.backgroundColor === "Grau") {
          color = "#f1f4f5"
        } else if (contentElement.backgroundColor === "Dunkelblau") {
          color = "#0F1923"
        }
        switch (contentElement.internal.type) {
          case "DatoCmsTextImage":
            if (contentElement.imageLinksOderRecht) {
              return (
                <div style={color !== "" ? { backgroundColor: color } : {}}>
                  <div className={styles.storyFlexContainer} key={i}>
                    <div
                      className={`${styles.storyImg1} ${
                        contentElement.shadow ? "shadow" : ""
                      }`}
                      style={
                        contentElement.image1 != null
                          ? {
                              backgroundImage: `url(${contentElement.image1.url})`,
                            }
                          : {}
                      }
                    >
                      <div className={styles.circleBg}></div>
                    </div>
                    <div
                      className={styles.storyText1}
                      style={(color === "#232020"|| color === "#0F1923")  ? { color: "#ffffff" } : {}}
                    >
                      {contentElement.berschriftH2 && (
                        <h2 className={styles.h2}>
                          {contentElement.berschriftH2}
                        </h2>
                      )}
                      {contentElement.text && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: contentElement.text,
                          }}
                        ></div>
                      )}
                    </div>
                  </div>
                </div>
              )
            } else if (contentElement.imageBelow) {
              return (
                <div style={color !== "" ? { backgroundColor: color } : {}}>
                  <div
                    className={styles.storyFlexContainer2}
                    style={{ flexDirection: "column" }}
                    key={i}
                  >
                    <div className={styles.storyText2}>
                      {contentElement.berschriftH2 && (
                        <h2 className={styles.h2}>
                          {contentElement.berschriftH2}
                        </h2>
                      )}
                      {contentElement.berschriftH3 && (
                        <h3 className={styles.h3}>
                          {contentElement.berschriftH3}
                        </h3>
                      )}
                      {contentElement.text && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: contentElement.text,
                          }}
                        ></div>
                      )}
                    </div>
                    <div
                      className={`${styles.storyImg2} ${
                        contentElement.shadow ? "shadow" : ""
                      }`}
                      style={
                        contentElement.image1 != null
                          ? {
                              backgroundImage: `url(${contentElement.image1.url})`,
                              width: "unset",
                            }
                          : {}
                      }
                    >
                      <div className={styles.circleBg}></div>
                    </div>
                  </div>
                </div>
              )
            } else {
              return (
                <div style={color !== "" ? { backgroundColor: color } : {}}>
                  <div className={styles.storyFlexContainer2} key={i}>
                    <div
                      className={styles.storyText1}
                      style={color === "#232020" ? { color: "#ffffff" } : {}}
                    >
                      {contentElement.berschriftH2 && (
                        <h2 className={styles.h2}>
                          {contentElement.berschriftH2}
                        </h2>
                      )}
                      {contentElement.text && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: contentElement.text,
                          }}
                        ></div>
                      )}
                    </div>
                    <div
                      className={`${styles.storyImg2} ${
                        contentElement.shadow ? "shadow" : ""
                      }`}
                      style={
                        contentElement.image1 !== null
                          ? {
                              backgroundImage: `url(${contentElement.image1.url})`,
                            }
                          : {}
                      }
                    >
                      <div className={styles.circleBg}></div>
                    </div>
                  </div>
                </div>
              )
            }
          case "DatoCmsBlurbListImage":
            return (
              <ShopwareDescription
                Headline={contentElement.headline}
                bgColor={contentElement.hintergrundfarbe}
                Icon1={imageSrc(contentElement.icon1)}
                Icon2={imageSrc(contentElement.icon2)}
                Icon3={imageSrc(contentElement.icon3)}
                Icon4={imageSrc(contentElement.icon4)}
                Icon5={imageSrc(contentElement.icon5)}
                Icon6={imageSrc(contentElement.icon6)}
                Iconshadow={contentElement.iconShadow}
                List1={contentElement.list1}
                List2={contentElement.list2}
                List3={contentElement.list3}
                List4={contentElement.list4}
                List5={contentElement.textIcon5}
                List6={contentElement.textIcon6}
                Img={imageSrc(contentElement.image)}
                Shadow={contentElement.bildShadow}
                ImgAlt={contentElement.image?.alt}
                ImgDesc={contentElement.imageDescription}
                key={i}
              />
            )
          case "DatoCmsPictureDivider":
            return (
              <PicDivider
                bgGradientColorLeft={contentElement.bgGradientColorLeft}
                bgGradientColorRight={contentElement.bgGradientColorRight}
                img={imageSrc(contentElement.image)}
                imgalt={contentElement.image?.alt}
                key={i}
              />
            )
          case "DatoCmsTable":
            return (
              <ShopwareComparison
                table={contentElement.content}
                headline={contentElement.headline}
                introduction={contentElement.introduction}
                key={i}
              />
            )
          case "DatoCmsTestimonial":
            return <Qoute quote={contentElement.quote1} key={i} />
          case "DatoCmsRating":
            return (
              <ShopwareExtention
                headline={contentElement.headlineH2}
                buttonName={contentElement.ctaName}
                buttonUrl={contentElement.ctaUrl}
                rating1={contentElement.rating1}
                rating2={contentElement.rating2}
                rating3={contentElement.rating3}
                rating4={contentElement.rating4}
                rating1Was={contentElement.rating1Was}
                rating2Was={contentElement.rating2Was}
                rating3Was={contentElement.rating3Was}
                rating4Was={contentElement.rating4Was}
                img={imageSrc(contentElement.smallImage)}
                imgalt={contentElement.smallImage?.alt}
                textAreaLeft={contentElement.textAreaLeft}
                textAreaRight={contentElement.textAreaRight}
                zahl1={contentElement.zahl1}
                zahl2={contentElement.zahl2}
                was1={contentElement.was1}
                was2={contentElement.was2}
                key={i}
              />
            )
          case "DatoCmsSimpletext":
            return (
              <div key={i} className={styles.contentContainer}>
                <div className={styles.headlineContainer}>
                  {contentElement.number && (
                    <p className={styles.headlineNumber}>
                      {" "}
                      {contentElement.number}
                    </p>
                  )}
                  {contentElement.headline && (
                    <h4
                      dangerouslySetInnerHTML={{
                        __html: contentElement.headline,
                      }}
                    ></h4>
                  )}
                </div>
                <div
                  className={styles.centerTextFont}
                  key={i}
                  dangerouslySetInnerHTML={{
                    __html: contentElement.text,
                  }}
                ></div>
              </div>
            )
          case "DatoCmsVideofullwidth":
            return (
              <div
                key={i}
                className={styles.bodyVideo}
                style={play ? { opacity: "1" } : { opacity: "0.5" }}
              >
                <video
                  title="video"
                  ref={vidRef}
                  onClick={pauseHandler}
                  poster={imageSrc(contentElement.vorschaubild)}
                  style={{
                    background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${imageSrc(
                      contentElement.vorschaubild
                    )}) no-repeat center center fixed`,
                    backgroundSize: "contain",
                  }}
                >
                  <track
                    default
                    kind="captions"
                    src={contentElement.videomp4.video.mp4Url}
                    srcLang="en"
                    label="English"
                  />
                  <source
                    src={contentElement.videomp4.video.mp4Url}
                    type="video/mp4"
                  />
                </video>
                <div
                  className={styles.playButtonContainer}
                  style={play ? { display: "none" } : { display: "block" }}
                >
                  <div className={styles.playButton}>
                    <div
                      role="button"
                      aria-label="video"
                      className={styles.playButtonIcon}
                      onClick={clickHnadler}
                      onKeyUp={clickHnadler}
                      tabIndex={0}
                    ></div>
                  </div>
                  <p>VIDEO ABSPIELEN</p>
                </div>
              </div>
            )
          default:
            return <div key={i}>unknown content element type</div>
        }
      })}
      <OurProjects
        items={validNodes}
        title={data.datoCmsLeistung.kategorie[0].name}
      />
      <Footer cookieConsentRef={cookieConsentRef} />
    </div>
  )
}

export const query = graphql`
  query($id: String!) {
    allDatoCmsReferenz {
      nodes {
        id
        kategorie {
          name
        }
        kundeKurz
        slug
        teaserText
        website
        titelDerReferenz
        technologie
        teaserbild {
          alt
          fluid(imgixParams: { auto: "compress" }) {
            src
          }
          url
        }
      }
    }
    datoCmsLeistung(id: { eq: $id }) {
      seo {
        description
        title
        twitterCard
      }
      kategorie {
        name
      }
      buttonName
      buttonUrl
      themenseiteTitle
      teaserText
      image1 {
        alt
        fluid(imgixParams: { auto: "compress" }) {
          src
        }
        url
      }
      image2 {
        alt
        fluid(imgixParams: { auto: "compress" }) {
          src
        }
        url
      }
      image3 {
        alt
        fluid(imgixParams: { auto: "compress" }) {
          src
        }
        url
      }
      content {
        ... on DatoCmsTextImage {
          id
          backgroundColor
          berschriftH2
          berschriftH3
          bulletPoints
          shadow
          image1 {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          text
          imageLinksOderRecht
          imageBelow
          internal {
            type
          }
        }
        ... on DatoCmsPictureDivider {
          id
          bgGradientColorLeft
          bgGradientColorRight
          image {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          internal {
            type
          }
        }
        ... on DatoCmsTable {
          id
          content
          headline
          introduction
          internal {
            type
          }
        }
        ... on DatoCmsRating {
          id
          ctaName
          ctaUrl
          headlineH2
          rating1
          rating1Was
          rating2
          rating2Was
          rating3
          rating3Was
          rating4
          rating4Was
          textAreaLeft
          textAreaRight
          was1
          zahl1
          was2
          zahl2
          smallImage {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          internal {
            type
          }
        }
        ... on DatoCmsBlurbListImage {
          id
          hintergrundfarbe
          list1
          list2
          list3
          list4
          textIcon5
          textIcon6
          bildShadow
          iconShadow
          hintergrundfarbe
          headline
          icon1 {
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          icon2 {
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          icon3 {
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          icon4 {
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          icon5 {
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          icon6 {
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          image {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          imageDescription
          internal {
            type
          }
        }
        ... on DatoCmsTestimonial {
          id
          anchorName
          quote1
          internal {
            type
          }
        }
        ... on DatoCmsSimpletext {
          number
          text
          headline
          internal {
            type
          }
          id
        }
        ... on DatoCmsVideofullwidth {
          id
          videomp4 {
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
            video {
              mp4Url
            }
          }
          vorschaubild {
            fluid {
              src
            }
            url
          }
          internal {
            type
          }
        }
      }
    }
  }
`
