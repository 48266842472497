import React from "react"

export default (props) => {

  if (!props.config) {
    return null;
  }

  const organizationData = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: props.config.companyName,
    address: {
      '@type': 'PostalAddress',
      addressLocality: props.config.companyAddressCity,
      postalCode: props.config.companyAddressZip,
      streetAddress: props.config.companyStreetAddress,
    },
    telephone: props.config.phoneNumber,
  };

  return (
      <>
        <script
            type='application/ld+json'
            dangerouslySetInnerHTML={{ __html: JSON.stringify(organizationData) }}
        />
      </>
  );
}